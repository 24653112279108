import React from 'react';
import {Box} from '../box';
import {SocialURL} from '../footer/types';
import {
  DiscordIcon,
  InstagramIcon,
  MediumIcon,
  TelegramIcon,
  TiktokIcon,
  TwitterIcon,
  YouTubeIcon,
} from '../icons';
import {FrameworkLink} from '../link-renderer';

export const ADVISORYS = [
  {title: 'Advisory services', url: '/advisory'},
  // {title: 'Case studies', url: '-'},
];

export const MARKETPLACES = [
  {title: 'Discover', url: '/discover'},
  {title: 'Artists', url: '/artists'},
  {title: 'Curators', url: '/curators'},
];

export const PROTOCOLS = [
  {title: 'Protocol', url: '/protocol'},
  {title: 'Openlab', url: '/studio/openlab'},
  {title: 'The Drop', url: '/studio/the-drop'},
  {title: 'Here At Last', url: '/studio/here-at-last'},
  {title: 'My Sahel', url: '/studio/my-sahel'},
  {title: 'Sample Tools by Cr2', url: '/studio/sample-tools-by-cr2'},
  {title: 'Earthsonic', url: '/studio/earthsonic'},
  {title: 'Npayme Marketplace', url: '/studio/npayme-marketplace'},
  // {title: 'Case studies', url: '-'},
];

export const ABOUTS = [
  {title: 'About', url: '/about'},
  {title: 'Mission', url: '/about/mission'},
  {title: 'Membership', url: '/about/membership'},
  {title: 'TRAXX Token', url: '/about/traxxtokens'},
  {title: 'Media hub', url: 'https://medium.com/tokentraxx', isNew: true},
  {
    title: 'Submit your music',
    url: '/getminted',
    isNew: true,
  },
];

export const MOBILE_NAV_ITEMS = [
  {title: 'Marketplace', subitems: MARKETPLACES},
  {title: 'TRAXX Studios', subitems: PROTOCOLS},
  {title: 'Advisory', subitems: ADVISORYS},
  {title: 'About', subitems: ABOUTS},
  {title: 'Socials', subitems: null},
];

export const studioSocialIcons: SocialURL[] = [
  {
    socialName: 'Twitter',
    url: 'https://twitter.com/TokenTraxx',
    icon: <TwitterIcon color="textLow" />,
  },
  {
    socialName: 'Discord',
    url: 'https://discord.com/invite/JyA7H4DyjG',
    icon: <DiscordIcon color="textLow" />,
  },
  {
    socialName: 'Telegram',
    url: 'https://t.me/TokenTraxxMusic',
    icon: <TelegramIcon color="textLow" />,
  },
];

export const footerSocialURLs: SocialURL[] = [
  {
    socialName: 'Twitter',
    url: 'https://twitter.com/TokenTraxx',
    icon: <TwitterIcon color="textDefault" />,
  },
  {
    socialName: 'Discord',
    url: 'https://discord.com/invite/JyA7H4DyjG',
    icon: <DiscordIcon color="textDefault" />,
  },
  {
    socialName: 'Telegram',
    url: 'https://t.me/TokenTraxxMusic',
    icon: <TelegramIcon color="textDefault" />,
  },
  {
    socialName: 'Medium',
    url: 'https://medium.com/tokentraxx',
    icon: <MediumIcon color="textDefault" />,
  },
  {
    socialName: 'Instagram',
    url: 'https://www.instagram.com/tokentraxx/',
    icon: <InstagramIcon color="textDefault" />,
  },
  {
    socialName: 'TikTok',
    url: 'https://www.tiktok.com/@tokentraxx',
    icon: <TiktokIcon color="textDefault" />,
  },
  {
    socialName: 'Youtube',
    url: 'https://www.youtube.com/channel/UCoBqzOCTafhAfs1-afCesnQ',
    icon: <YouTubeIcon color="textDefault" />,
  },
];

export const NavBarSocialLinks = () => {
  const items = footerSocialURLs;
  return (
    <Box justifyContent="center" paddingX="seven">
      <Box flex="1" flexDirection="row">
        {items.slice(0, 3).map((e) => (
          <FrameworkLink href={e.url}>
            <Box m="two">{e.icon}</Box>
          </FrameworkLink>
        ))}
      </Box>
    </Box>
  );
};

export const ROUTERS_HIDE_WALLET_CONNECT = [
  'advisory',
  'protocol',
  'about',
  'getminted',
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const matchWithRoute = (currentHref: string) => {
  return !!ROUTERS_HIDE_WALLET_CONNECT.find((e) => currentHref.indexOf(e) > -1);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isActiveNavRoute = (currentHref: string, subRoutes: string[]) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < subRoutes.length; i++) {
    if (currentHref.indexOf(subRoutes[i]) > -1) {
      return true;
    }
  }
  return false;
};
