export const POYLMESH_EXTN_NAME = 'polywallet';
export const APP_NAME = 'TokenTraxx';
export const TESTNET_CHAIN = 99999; // this is set by us for our purpose; there is no chain ID with this type
export const MAINNET_CHAIN = 999; // this is set by us for our purpose; there is no chain ID with this type

export const TICKER = "";


// TODO: Need to get this Artist data from the backend
// TESTNET
export const whitelistedArtistList = [
  {
    artistHandle: null,
    artistImage:
      'https://www.datocms-assets.com/73624/1708607354-dall-e-2024-02-21-14-29-12-imagine-a-profile-picture-for-kane-fifita-a-dynamic-entrepreneur-in-the-e-gaming-industry-kane-is-the-ceo-and-co-founder-of-shadow-gaming-a-revolut.jpg',
    artistName: 'Kurt Pittman',
    // id: '65d894034fea2479bd67df4b',
    id: '0x944bae358fbb4119243c66fbd0856e9f79d8ec328877cac5b054488b43b46fc2',
    numOfCollections: 1,
    slug: 'GOING',
    portfolio: 'TT X ARTIST',
  },
  {
    artistHandle: null,
    artistImage:
      'https://www.datocms-assets.com/73624/1708612550-dall-e-2024-02-22-14-35-10-create-a-stunning-cover-image-for-oisin-murphys-website-that-encapsulates-the-fusion-of-traditional-irish-music-with-the-vibrancy-of-contemporary-cul.jpg',
    artistName: 'Oisin Murphy',
    // id: '65d894224fea2479bd67df66',
    id: '0x944bae358fbb4119243c66fbd0856e9f79d8ec328877cac5b054488b43b46fc2',
    numOfCollections: 1,
    slug: 'USOPP',
    portfolio: 'TT X ARTIST',
  },
  {
    artistHandle: null,
    artistImage:
      'https://www.datocms-assets.com/73624/1708612550-dall-e-2024-02-22-14-35-10-create-a-stunning-cover-image-for-oisin-murphys-website-that-encapsulates-the-fusion-of-traditional-irish-music-with-the-vibrancy-of-contemporary-cul.jpg',
    artistName: 'Evan',
    // id: '65d894224fea2479bd67df66',
    id: '0x63efd1ef9b0d3dd0313e5cb33cd462dc708c4b647c884cd52c793f8726392e86',
    numOfCollections: 1,
    slug: 'LOVENFT',
    portfolio: 'TT X ARTIST',
  },
  {
    artistHandle: null,
    artistImage:
      'https://www.datocms-assets.com/73624/1708607354-dall-e-2024-02-21-14-29-12-imagine-a-profile-picture-for-kane-fifita-a-dynamic-entrepreneur-in-the-e-gaming-industry-kane-is-the-ceo-and-co-founder-of-shadow-gaming-a-revolut.jpg',
    artistName: 'Luffy',
    // id: '65d894224fea2479bd67df66',
    id: '0x6c3133de3d9cdaac3738af5914097867f84c51674672df8c5b759b1600479441',
    numOfCollections: 1,
    slug: 'LIONNFT',
    portfolio: 'tt custody',
  },
];
